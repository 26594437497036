<template>
    <div class="container">
      <!-- <div class="module header" :style="{background: `rgba(255,255,255, ${scrollHeight / 100 + 0.2})`, boxShadow: `0 0 10px rgba(204,204,204, ${scrollHeight / 100})`}">
        <header-module></header-module>
      </div> -->
      <!-- <el-affix>
      </el-affix> -->
      <div class="module banner" id="home">
        <banner-module :data="bannerData"></banner-module>
      </div>
      <div class="module" id="about">
        <about-module></about-module>
      </div>
      <div class="module" id="platform">
        <platform-module></platform-module>
      </div>
      <div class="module" id="cooperative">
        <cooperative-module></cooperative-module>
      </div>
      <div class="module" id="contact">
        <contact-module></contact-module>
      </div>
      <div class="footer">
        <div class="footer-inner">
            <div>
              Copyright 2024 SUMOU All Rights Reserved 蜀ICP备2023042080号-1
            </div>
            <div>川公网安备51012202001705</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import HeaderModule from "@/components/mobile/Header.vue";
  import BannerModule from "@/components/mobile/Banner.vue";
  import AboutModule from "@/components/mobile/About.vue";
  import CooperativeModule from "@/components/mobile/Cooperative.vue";
  import PlatformModule from "@/components/mobile/Platform";
  import ContactModule from "@/components/mobile/Contact";
  
  import "animate.css";
  export default {
    name: "home-page",
    components: {
    //   HeaderModule,
      BannerModule,
      AboutModule,
      CooperativeModule,
      PlatformModule,
      ContactModule,
    },
    data() {
      return {
        isMobile: true,
        scrollHeight: 0
      };
    },
    methods: {
      handleScroll() {
        this.scrollHeight = window.scrollY / 3;
        console.log('handleScroll: ', window.scrollY);
        // 处理滚动事件
      },

    },
    mounted() {
    },
  };
  </script>
  
  <style scoped lang="less">
  .container {
    overflow: hidden;
    .module {
      background: #fff;
      &.header {
        position: fixed;
        background: transparent;
        z-index: 10;
        width: 100%;
        top: 0;
        height: 81px;
        box-shadow: 0 0 10px rgb(204, 204, 204);
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      background: #666;
      color: #fff;
      padding: 10px 0;
      .footer-inner {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  </style>